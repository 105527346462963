import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "antd";
import { useSelector } from "react-redux";
import { AuthWrapper, ButtonWrapper } from "./style";
import Heading from "../../../../components/heading/heading";
import { toast } from "react-toastify";
import { useOktaAuth } from "@okta/okta-react";

function SignIn() {
  const { oktaAuth } = useOktaAuth();

  const login = async () => oktaAuth.signInWithRedirect();

  const history = useHistory();
  const [form] = Form.useForm();

  const { loading, error } = useSelector((state) => state.data);

  if (error) {
    toast.error(error);
  }

  const handleSubmit = async (values) => {
    login();
    // dispat'ch(Adminlogin(values));
  };

  const handleClick = () => {
    history.push("/contact-us");
  };

  return (
    <AuthWrapper>
      {/* <p className="auth-notice">
        Don&rsquo;t have an account? <NavLink to="#">Sign up now</NavLink>
      </p> */}
      <div className="auth-contents login-form">
        <Form
          name="login"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Heading as="h3" className="text-center">
            Sign in to{" "}
            <span className="color-success text-center">Demo</span>
          </Heading>

          <Form.Item>
            <ButtonWrapper>
              <Button
                className="btn-signin"
                htmlType="submit"
                type="primary"
                size="large"
              >
                {loading ? "Loading..." : "Sign In"}
              </Button>
            </ButtonWrapper>
          </Form.Item>
        </Form>

        <span onClick={handleClick} className="btn-contact">
          Contact Us
        </span>
      </div>
    </AuthWrapper>
  );
}

export default SignIn;
